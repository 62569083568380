<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="text-dark">Service Rate Calculations</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item" role="presentation">
            <a
              :href="
                $router.resolve({
                  name: 'ServiceRateCalculations',
                  params: { serviceType: 'people' },
                  query: $route.query,
                }).href
              "
              :class="{ 'nav-link': true, active: $data.serviceType == 'people' }"
            >
              People
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              :href="
                $router.resolve(
                  {
                    name: 'ServiceRateCalculations',
                    params: { serviceType: 'package' },
                    query: $route.query,
                  },
                  null,
                  true
                ).href
              "
              :class="{ 'nav-link': true, active: $data.serviceType == 'package' }"
            >
              Package
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              :href="
                $router.resolve({
                  name: 'ServiceRateCalculations',
                  params: { serviceType: 'product' },
                  query: $route.query,
                }).href
              "
              :class="{ 'nav-link': true, active: $data.serviceType == 'product' }"
            >
              Product
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <component :is="currentComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
const People = defineAsyncComponent(() => import('./People'));
const Package = defineAsyncComponent(() => import('./Package'));
const Product = defineAsyncComponent(() => import('./Product'));

export default {
  name: 'ServiceRateCalculations2',
  // props: ['type'],
  data: function () {
    return {
      serviceType: '',
      objQueryString: {},
    };
  },
  // components: { People, Package, Product },

  computed: {
    currentComponent() {
      // let type = this.$props.type;
      let type = this.$route.params.serviceType;
      if (type == 'package') return Package;
      if (type == 'product') return Product;
      return People;
    },
  },
  created() {
    this.$data.serviceType = this.$route.params.serviceType;
  },
  mounted() {
    if (this.$route.query.currency != undefined) {
      let currencyCode = this.$route.query.currency;
      currencyCode = currencyCode.toUpperCase();
      let currencySymbol = '';
      let obj = {
        currencyCode: currencyCode,
        currencySymbol: currencySymbol,
      };
      this.$store.commit('changeCurrency', obj);
    }
  },
};
</script>

<style scoped></style>
